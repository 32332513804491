import { RunTimeDriver } from "@mongez/cache";
import Endpoint, { setCurrentEndpoint } from "@mongez/http";
import { getCurrentLocaleCode } from "@mongez/localization";
import { navigateTo } from "@mongez/react-router";
import user from "apps/front-office/account/user";
import { settingsAtom } from "apps/front-office/common/atoms";
import URLS from "apps/front-office/utils/urls";
import { AxiosResponse } from "axios";
import { __DEV__, apiBaseUrl, apiKey } from "./flags";

const endpoint = new Endpoint({
  putToPost: true,
  baseURL: apiBaseUrl,
  cache: false,
  cacheOptions: {
    driver: new RunTimeDriver(),
    expiresAfter: 60 * 60 * 24 * 7, // 1 week, but because it is a runtime driver, it will be cleared when the page is refreshed
  },
  setAuthorizationHeader: () => {
    if (user.isLoggedIn()) {
      return `Bearer ${user.getAccessToken()}`;
    }

    return `key ${apiKey}`;
  },
});

const endpointEvents = endpoint.events;

endpointEvents.beforeSending(config => {
  const headers: any = config.headers;
  if (__DEV__) {
    // headers["client-id"] = 238698;
    // headers["client-id"] = "alhadaf-academy.com";
    headers["client-id"] = "shipria-academy.com";
  }

  headers["locale-code"] = getCurrentLocaleCode();

  if (settingsAtom.get("state") === "initial") {
    settingsAtom.change("state", "loading");
    headers["ws"] = true; // ws -> with settings
  }
});

endpointEvents.onSuccess((response: AxiosResponse) => {
  if (response.data.data) {
    response.data = response.data.data;
  }

  if (response?.data?.settings) {
    settingsAtom.update(response.data.settings);
  }

  if (response.data.user) {
    user.login(response.data.user);
  }
});

endpointEvents.onError(response => {
  if (response.data?.data) {
    response.data = response.data.data;
  }

  if (response.status === 401) {
    user.logout();
    navigateTo(URLS.home);
  }
});

setCurrentEndpoint(endpoint);

export default endpoint;

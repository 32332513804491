import { setMoonlightConfigurations } from "@mongez/moonlight";
import { currentRoute, navigateTo, queryString } from "@mongez/react-router";
import { localeCodesList } from "app/utils/localization";
import user from "apps/front-office/account/user";
import URLS from "apps/front-office/utils/urls";
import endpoint from "./endpoint";

setMoonlightConfigurations({
  localeCodes: localeCodesList,
  endpoint: endpoint,
  router: {
    currentRoute: currentRoute,
    navigateTo: navigateTo,
    notFoundRoute: URLS.notfound,
    queryString: {
      all: queryString.all.bind(queryString),
      get: queryString.get.bind(queryString),
      update: queryString.update.bind(queryString),
    },
  },
  user: user,
  uploads: {
    route: "/uploads",
    resolveResponse(response) {
      return response.data.uploads;
    },
  },
});

import { currentRoute } from "@mongez/react-router";
import endpoint from "shared/endpoint";
import { __DEV__ } from "shared/flags";

export function logErrors() {
  if (__DEV__) return;

  // Add an event listener for unhandled promise rejections
  window.addEventListener("unhandledrejection", event => {
    const reason = event.reason;
    console.error("Unhandled Promise Rejection:", reason);

    // Log promise rejections to the server
    const errorData = {
      message: "Unhandled Promise Rejection",
      reason: reason instanceof Error ? reason.message : reason,
      path: currentRoute(),
    };

    reportErrorToServer(errorData);
  });

  // Add an event listener for unhandled errors
  window.addEventListener("error", event => {
    const { message, filename, lineno, colno, error } = event;
    const stack = error?.stack;

    // Log JavaScript errors to the server
    const errorData = {
      message,
      filename,
      lineno,
      colno,
      stack,
      path: currentRoute(),
    };

    reportErrorToServer(errorData);
  });
}

function reportErrorToServer(errorData: any) {
  // You can enhance this function to send error data to the server endpoint
  // using your specific implementation, such as making an HTTP POST request.
  // Here's a simplified example using fetch:

  endpoint.post("/logs/errors", errorData);
}

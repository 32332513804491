import cache from "@mongez/cache";
import { setDescription, setImage, setTitle } from "@mongez/dom";
import { atom } from "@mongez/react-atom";
import { setGoogleMapConfigurations } from "@mongez/react-google-map";
import { setHelmetConfigurations } from "@mongez/react-helmet";
import { Attachment } from "apps/front-office/utils/use-attachments";

export type SettingsState = {
  state: "initial" | "loading" | "loaded";
  general: {
    appName: string;
    allowRegistration: boolean;
    closedRegistrationText: string;
    description: string;
    about: string;
    vodafoneCash: number;
    maxInactiveAccountTime: number;
    logo: Attachment;
    favicon?: Attachment;
    paymentMethods?: string[];
  };
  home: {
    aboutUsSectionImage: Attachment;
    aboutUsSectionText: string;
    achievementsSectionsImage: Attachment;
    achievementsSectionsText: string;
    heroSectionImage: Attachment;
  };
  contact: {
    address: string;
    email: string;
    phoneNumber: string;
    location: {
      address: string;
      lat: number;
      lng: number;
    };
  };
  integrations: {
    googleMap: string;
  };
  social: {
    facebook: string;
    twitter: string;
    instagram: string;
    youtube: string;
  };
};

function setupSettings(settings: SettingsState) {
  if (settings?.general?.description) {
    setDescription(settings.general.description);
  }

  if (settings?.integrations?.googleMap) {
    setGoogleMapConfigurations({
      apiKey: settings.integrations.googleMap,
    });
  }

  if (settings?.general?.appName) {
    setHelmetConfigurations({
      appendAppName: true,
      appNameSeparator: " | ",
      translatable: false,
      translateAppName: false,
      appName: settings.general.appName,
    });
  }

  if (settings?.general?.appName) {
    setTitle(settings.general.appName);
  }

  if (settings?.general?.logo?.url) {
    setImage(settings.general.logo.url);
  }

  if (settings?.general?.favicon?.url) {
    const faviconElements = document.querySelectorAll(".favicon");

    faviconElements.forEach(element => {
      element.setAttribute("href", settings!.general!.favicon!.url);
    });
  }

  return settings;
}

export const settingsAtom = atom<SettingsState>({
  key: "settings",
  beforeUpdate(settings) {
    settings.state = "loaded";
    setupSettings(settings);
    cache.set("ss", settings);
    return settings;
  },
  default: {
    ...setupSettings(
      cache.get("ss", {
        state: "initial",
      }),
    ),
    state: "initial", // force get updated settings
  },
});
